import React from "react";
import token from "../Images/image5.png";
import {
    Box,
    Button,
    Container,
    Grid,
    Hidden,
    Typography,
    styled,
    useMediaQuery,
  } from "@mui/material";
  import linktree from "../Images/linktree.png";
import tweet from "../Images/tweet.png";
import tele from "../Images/tele.png";
import youtube from "../Images/youtube.png";
import med from "../Images/med.png";
import { FaRedditAlien } from "react-icons/fa";
import { CountdownMonths } from "./CountdownMonths";

export default function RealWorld() {
  const matches = useMediaQuery("(max-width:950px)");
  const matches1 = useMediaQuery("(max-width:1390px)");

  const Paragraph = styled(Typography)({
    fontWeight: 400,
    fontFamily: "'Poppins'",
    fontSize: "18px",
    lineHeight: "30px",
    color: "#fff",
  });

  return (
    <>
      <Container maxWidth="xl">
            <Grid
              container
              spacing={3}
              display="flex"
              alignItems="center"
              px={!matches && 3}
              py={matches ? 5 : 6}
              className="xs-reverse"
            >
              <Grid
                item
                md={6}
                xs={12}
                justifyContent="flex-start"
                alignItems="center"
                display="flex"
                pb={matches && 2}
              >
                <Box>
                  <Hidden mdDown>
                    <Box display="flex" alignItems="center">
                      <a
                        href="https://linktr.ee/retikfinance"
                        style={{ textDecoration: "none" }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={linktree}
                          width="30px"
                          style={{ marginRight: "15px" }}
                          alt=""
                        />
                      </a>
                      <a
                        href="https://twitter.com/retikfinance"
                        style={{ textDecoration: "none" }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={tweet}
                          width="30px"
                          style={{ marginRight: "15px" }}
                          alt=""
                        />
                      </a>
                      <a
                        href="https://t.me/retikfinance"
                        style={{ textDecoration: "none" }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={tele}
                          width="30px"
                          style={{ marginRight: "15px" }}
                          alt=""
                        />
                      </a>
                      <a
                        href="https://www.youtube.com/@retikfinance"
                        style={{ textDecoration: "none" }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={youtube}
                          width="30px"
                          style={{ marginRight: "15px" }}
                          alt=""
                        />
                      </a>
                      <a
                        href="https://medium.com/@retikfinance"
                        style={{ textDecoration: "none" }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={med}
                          width="30px"
                          style={{ marginRight: "15px" }}
                          alt=""
                        />
                      </a>
                      <a
                        href="https://reddit.com/r/retikfinance"
                        style={{ textDecoration: "none" }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaRedditAlien
                          style={{ fontSize: "30px", color: "#B57DFE" }}
                        />
                      </a>
                    </Box>
                  </Hidden>
                  {/* <MianHeading mt={matches ? -3 : 3}>
                    Step Into The Future with{" "}
                    <span
                      style={{
                        background: "transparent",
                        backgroundImage:
                          "linear-gradient(90deg, rgba(109,216,212,1) 10%, rgba(243,137,192,1) 60%)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      Retik Finance
                    </span>{" "}
                  </MianHeading> */}
                  <p className="title-animation">
                    Step Into The Future{" "}<br />with Retik Finance
                  </p>
                  <Paragraph mt={4} pr={matches ? 0 : 2}>
                    Retik Finance innovates in DeFi with it's Futuristic DeFi
                    Debit Cards, Smart Crypto Payment Gateway, AI-Powered Peer-to-Peer (P2P) Lending and Multi-Chain Non-Custodial Highly
                    Secured DeFi Wallet, aiming to redefine global transactions.
                  </Paragraph>
                  <Box
                    mt={matches ? 4 : 9}
                    display="flex"
                    flexDirection={matches ? "column" : "row"}
                  >
                    <a
                      href="https://retik.com/whitepaper.pdf"
                      style={{ textDecoration: "none" }}
                      target="_blank"
                    >
                      <Button
                        sx={{
                          color: "#fff",
                          backgroundImage: "linear-gradient(120deg, #6562fa 0%, #B781FF 100%)",
                          fontSize: matches ? "16px" : "20px",
                          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                          textTransform: "capitalize",
                          fontFamily: "Poppins",
                          borderRadius: "18px",
                          marginRight: "20px",
                          width: matches ? "100%" : "170px",
                          height: "50px",
                        }}
                      >
                        Whitepaper
                      </Button>
                    </a>
                    <a
                      href="https://retik.com/audit"
                      style={{ textDecoration: "none" }}
                      target="_blank"
                    >
                      <Button
                        sx={{
                          color: "#fff",
                          backgroundImage: "linear-gradient(120deg, #6562fa 0%, #B781FF  100%)",
                          fontSize: matches ? "16px" : "20px",
                          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                          textTransform: "capitalize",
                          fontFamily: "Poppins",
                          borderRadius: "16px",
                          marginRight: "20px",
                          width: matches ? "100%" : "170px",
                          height: "50px",
                          mt: matches && 2,
                        }}
                      >
                        Audit
                      </Button>
                    </a>
                  </Box>
                  <Hidden mdUp>
                    <Box
                      display="flex"
                      alignItems="center"
                      mt={3}
                      justifyContent="center"
                    >
                      <a
                        href="https://linktr.ee/retikfinance"
                        style={{ textDecoration: "none" }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={linktree}
                          width="30px"
                          style={{ marginRight: "10px" }}
                          alt=""
                        />
                      </a>
                      <a
                        href="https://twitter.com/retikfinance"
                        style={{ textDecoration: "none" }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={tweet}
                          width="30px"
                          style={{ marginRight: "10px" }}
                          alt=""
                        />
                      </a>
                      <a
                        href="https://t.me/retikfinance"
                        style={{ textDecoration: "none" }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={tele}
                          width="30px"
                          style={{ marginRight: "10px" }}
                          alt=""
                        />
                      </a>
                      <a
                        href="https://www.youtube.com/@retikfinance"
                        style={{ textDecoration: "none" }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={youtube}
                          width="30px"
                          style={{ marginRight: "10px" }}
                          alt=""
                        />
                      </a>
                      <a
                        href="https://medium.com/@retikfinance"
                        style={{ textDecoration: "none" }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={med}
                          width="30px"
                          style={{ marginRight: "10px" }}
                          alt=""
                        />
                      </a>
                      <a
                        href="https://reddit.com/r/retikfinance"
                        style={{ textDecoration: "none" }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaRedditAlien
                          style={{ fontSize: "30px", color: "#B57DFE" }}
                        />
                      </a>
                    </Box>
                  </Hidden>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                display="flex"
                justifyContent="flex-end"
              >
                <Box
                  pb={5}
                  px={!matches && 5}
                  sx={{
                    width: matches ? "100%" : "80%",
                    background: matches
                      ? "radial-gradient(54.8% 53% at 50% 50%, #151515 0, #15151500 100%), linear-gradient(152.97deg, #fff3, #fff0)"
                      : "transparent",
                    backdropFilter: "blur(30px)",
                    borderRadius: matches ? "16px" : "25px",
                    textAlign: "center",
                    border: matches ? "1px solid #414247" : "1px solid #fff",
                    height: "100%",
                  }}
                >
                  <Typography
                    py={2}
                    sx={{
                      fontSize: matches ? "30px" : "3rem",
                      fontWeight: "700",
                      fontFamily: "Poppins",
                      textAlign: "center",
                      color: "#fff",
                    }}
                  >
                    <span
                      style={{
                        background: "transparent",
                        backgroundImage:
                          "linear-gradient(90deg, rgba(109,216,212,1) 10%, rgba(243,137,192,1) 60%)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      Retik{" "}
                    </span>
                    Launch
                  
                  </Typography>

                 <CountdownMonths />

                
                  {/* <Typography
                    pt={1}
                    sx={{
                      fontSize: matches ? "18px" : "22px",
                      fontWeight: "400",
                      fontFamily: "Poppins",
                      textAlign: "center",
                      color: "#fff",
                    }}
                  >
                    Tokens to Unlock:{" "}
                    <span
                      style={{
                        color: "gold",
                      }}
                    >
                      {formatNumberWithCommas(
                        parseFloat(nextUnlockTokens).toFixed(0)
                      )}
                    </span>{" "}
                    RETIK
                  </Typography> */}

                
                </Box>
               
              </Grid>
            </Grid>
          </Container>
    </>
  );
}
