import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import token from "../Images/giveaway.png";

export default function Faq() {
  const matches = useMediaQuery("(max-width:950px)");
  const matches1 = useMediaQuery("(max-width:1390px)");

  return (
    <>
      <Container maxWidth="xl">
        <div container className="common-gap p-t-0 text-center">
          <h4 className="faq-title">FAQ</h4>
          <p className="faq-p">You may have questions, we have the answers.</p>
          <div className="custom-accordion">
            <Accordion
              sx={{
                backgroundColor: "transparent",
                color: "#fff",
                fontSize: "1.5rem",
                fontWeight: "600",
                boxShadow: "none",
                marginTop: "24px",
                '&:before': {
                display: 'none',
            }
              }}
            >
              <AccordionSummary
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{borderBottom: "2px solid #4b4c59", boxShadow: 'none'}}
              >
                <div className="accordion-flex">
                  <span>What is Retik Finance?</span>{" "}
                  <span className="plus-icon">+</span>
                </div>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  textAlign: "left",
                  color: "#fff",
                  opacity: 0.6,
                  padding: "0 0 0 0",

                  fontSize: "16px",
                  fontWeight: "400",
                  paddingTop: "1.25rem",
                }}
              >
                Retik Finance is a DeFi ecosystem that provides a variety of
                financial tools and services, including DeFi debit cards, smart
                payment gateways, P2P lending, and a secure wallet. It aims to
                redefine how people conduct financial transactions in the
                digital age.
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                backgroundColor: "transparent",
                color: "#fff",
                fontSize: "1.5rem",
                fontWeight: "600",
                boxShadow: "none",
                marginTop: "24px",
                '&:before': {
                display: 'none',
            }
              }}
            >
              <AccordionSummary
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{borderBottom: "2px solid #4b4c59", boxShadow: 'none'}}
              >
                <div className="accordion-flex">
                  <span>What is RETIK Token?</span>{" "}
                  <span className="plus-icon">+</span>
                </div>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  textAlign: "left",
                  color: "#fff",
                  opacity: 0.6,
                  padding: "0 0 0 0",
                  fontSize: "16px",
                  fontWeight: "400",
                  paddingTop: "1.25rem",
                }}
              >
                RETIK is a community-driven governance token for the Retik
                Finance platform, allowing token holders to participate in
                decision-making processes and help shape the future of the
                ecosystem.
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                backgroundColor: "transparent",
                color: "#fff",
                fontSize: "1.5rem",
                fontWeight: "600",
                boxShadow: "none",
                marginTop: "24px",
                '&:before': {
                display: 'none',
            }
              }}
            >
              <AccordionSummary
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{borderBottom: "2px solid #4b4c59", boxShadow: 'none'}}
              >
                <div className="accordion-flex">
                  <span>How & Where can I buy RETIK token?</span>{" "}
                  <span className="plus-icon">+</span>
                </div>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  textAlign: "left",
                  color: "#fff",
                  opacity: 0.6,
                  padding: "0 0 0 0",
                  fontSize: "16px",
                  fontWeight: "400",
                  paddingTop: "1.25rem",
                }}
              >
               RETIK token is scheduled to list on both centralized (CEX) and decentralized (DEX) exchanges on May 21st, 2024.
               <p>
               Following the launch, you can buy RETIK tokens from various platforms, including: Retik Swap, Uniswap and the exchanges where RETIK is listed. Retik's website: <a href="https://retik.com" target="_blank" class="link-color">https://retik.com</a>
               . Please exercise caution and ensure you're on the official platform to avoid fraudulent schemes.</p>
               <p>Stay tuned for more updates and get ready to join the RETIK community!
               </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                backgroundColor: "transparent",
                color: "#fff",
                fontSize: "1.5rem",
                fontWeight: "600",
                boxShadow: "none",
                marginTop: "24px",
                '&:before': {
                display: 'none',
            }
              }}
            >
              <AccordionSummary
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{borderBottom: "2px solid #4b4c59", boxShadow: 'none'}}
              >
                <div className="accordion-flex">
                  <span>When and where will I receive my RETIK tokens?</span>{" "}
                  <span className="plus-icon">+</span>
                </div>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  textAlign: "left",
                  color: "#fff",
                  opacity: 0.6,
                  padding: "0 0 0 0",
                  fontSize: "16px",
                  fontWeight: "400",
                  paddingTop: "1.25rem",
                }}
              >
                You will be able to claim your RETIK tokens following the launch. This claiming process will take place on Retik's official website. All the details regarding claim and vesting will be shared on our website prior to launch.
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                backgroundColor: "transparent",
                color: "#fff",
                fontSize: "1.5rem",
                fontWeight: "600",
                boxShadow: "none",
                marginTop: "24px",
                '&:before': {
                display: 'none',
            }
              }}
            >
              <AccordionSummary
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{borderBottom: "2px solid #4b4c59", boxShadow: 'none'}}
              >
                <div className="accordion-flex">
                  <span>How many presale stages are there?</span>{" "}
                  <span className="plus-icon">+</span>
                </div>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  textAlign: "left",
                  color: "#fff",
                  opacity: 0.6,
                  padding: "0 0 0 0",
                  fontSize: "16px",
                  fontWeight: "400",
                  paddingTop: "1.25rem",
                }}
              >
                Retik Finance's presale consists of 10 stages. For additional
                details, please visit:{" "}
                <a href="https://retik.com/presale" className="link-color">
                  https://retik.com/presale
                </a>
                .
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                backgroundColor: "transparent",
                color: "#fff",
                fontSize: "1.5rem",
                fontWeight: "600",
                boxShadow: "none",
                marginTop: "24px",
                '&:before': {
                display: 'none',
            }
              }}
            >
              <AccordionSummary
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{borderBottom: "2px solid #4b4c59", boxShadow: 'none'}}
              >
                <div className="accordion-flex">
                  <span>When and where will RETIK launch?</span>{" "}
                  <span className="plus-icon">+</span>
                </div>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  textAlign: "left",
                  color: "#fff",
                  opacity: 0.6,
                  padding: "0 0 0 0",
                  fontSize: "16px",
                  fontWeight: "400",
                  paddingTop: "1.25rem",
                }}
              >
                RETIK token is scheduled to list on both centralized (CEX) and decentralized (DEX) exchanges on May 21st, 2024.
Stay tuned for official announcements about the specific launch time and trading platforms by following our social media channels.
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                backgroundColor: "transparent",
                color: "#fff",
                fontSize: "1.5rem",
                fontWeight: "600",
                boxShadow: "none",
                marginTop: "24px",
                '&:before': {
                display: 'none',
            }
              }}
            >
              <AccordionSummary
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{borderBottom: "2px solid #4b4c59", boxShadow: 'none'}}
              >
                <div className="accordion-flex">
                  <span>When can I apply for my Retik DeFi Debit Card?</span>{" "}
                  <span className="plus-icon">+</span>
                </div>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  textAlign: "left",
                  color: "#fff",
                  opacity: 0.6,
                  padding: "0 0 0 0",
                  fontSize: "16px",
                  fontWeight: "400",
                  paddingTop: "1.25rem",
                }}
              >
               Retik's DeFi debit cards are available, you can get your card at <a href="https://retikcards.com" className="link-color">https://retikcards.com</a>.
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                backgroundColor: "transparent",
                color: "#fff",
                fontSize: "1.5rem",
                fontWeight: "600",
                boxShadow: "none",
                marginTop: "24px",
                '&:before': {
                display: 'none',
            }
              }}
            >
              <AccordionSummary
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{borderBottom: "2px solid #4b4c59", boxShadow: 'none'}}
              >
                <div className="accordion-flex">
                  <span>My wallet is compromised/hacked, What should I do?</span>{" "}
                  <span className="plus-icon">+</span>
                </div>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  textAlign: "left",
                  color: "#fff",
                  opacity: 0.6,
                  padding: "0 0 0 0",
                  fontSize: "16px",
                  fontWeight: "400",
                  paddingTop: "1.25rem",
                }}
              >
              If you suspect that your wallet has been compromised, it's essential to take immediate action to protect your assets. Please follow these steps:
              <p><b>Never Share Your Seed Phrase or Private Key:</b> Under no circumstances should you share your Seed Phrase or Private Key with anyone. These are sensitive and should be kept secure.</p>
              <p><b>Contact Us:</b> Reach out to us at: <a href="https://retik.com/help" className="link-color">https://retik.com/help</a> to report the issue and seek guidance on how to proceed.</p>
              <p><b>Important Note:</b> While we can assist you in claiming RETIK tokens in the unfortunate event of your wallet being compromised or hacked, it's crucial to understand that we cannot recover lost funds. Protecting your assets remains a top priority.</p>
              <p><b>Beware of Scammers:</b> Remember that we will never ask for your private details, such as your Seed Phrase or Private Key. Be cautious of potential scams and always verify the authenticity of the support you're engaging with.</p>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </Container>
    </>
  );
}
